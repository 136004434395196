<template>
  <a-menu
    v-model:openKeys="openKeys"
    v-model:selectedKeys="selectedKeys"
    :mode="mode"
    :theme="darkMode ? 'dark' : 'light'"
  >
  <a-sub-menu key="dashboard">
    <template v-slot:title><sdFeatherIcons type="home" /><span>Dashboard</span></template>
    <a-menu-item @click="toggleCollapsed" key="starter">
      <router-link to="/">
        <sdFeatherIcons type="menu" />
        <span>
          Onboarding
        </span>
      </router-link>
    </a-menu-item>
    <a-menu-item @click="toggleCollapsed" key="starter">
      <router-link to="/products">
        <sdFeatherIcons type="rss" />
        <span>
          Products
        </span>
      </router-link>
    </a-menu-item>
    <a-menu-item @click="toggleCollapsed" key="starter">
      <router-link to="/reviews">
        <sdFeatherIcons type="smile" />
        <span>
          Reviews
        </span>
      </router-link>
    </a-menu-item>
  </a-sub-menu>
  <a-sub-menu key="reports">
    <template v-slot:title><sdFeatherIcons type="bar-chart-2" /><span>Reports</span></template>
    <a-menu-item @click="toggleCollapsed" key="starter">
      <router-link to="/reports/visits_behaviour">
        <sdFeatherIcons type="bar-chart-2" />
        <span>
          Visitors Behaviour
        </span>
      </router-link>
    </a-menu-item>
    <a-menu-item @click="toggleCollapsed" key="starter">
      <router-link to="/reports/top">
        <sdFeatherIcons type="bar-chart-2" />
        <span>
          Tops
        </span>
      </router-link>
    </a-menu-item>

    <a-menu-item @click="toggleCollapsed" key="starter">
      <router-link to="/reports/behavior">
        <sdFeatherIcons type="bar-chart-2" />
        <span>
          Attributes maps
        </span>
      </router-link>
    </a-menu-item>

    <a-menu-item @click="toggleCollapsed" key="starter">
      <router-link to="/reports/sales">
        <sdFeatherIcons type="bar-chart-2" />
        <span>
          Sales
        </span>
      </router-link>
    </a-menu-item>
  </a-sub-menu>
  <a-sub-menu key="billing">
    <template v-slot:title><sdFeatherIcons type="bar-chart-2" /><span>Usage & Budget</span></template>
    <a-menu-item key="starter">
      <router-link to="/reports/recommendation_usage">
        <sdFeatherIcons type="bar-chart-2" />
        <span>
          Usage & Budget
        </span>
      </router-link>
    </a-menu-item>
    <a-menu-item key="starter">
      <router-link to="/billing">
        <sdFeatherIcons type="credit-card" />
        <span>
          Billing
        </span>
      </router-link>
    </a-menu-item>
  </a-sub-menu>
  <a-sub-menu key="Settings">  
    <template v-slot:title><sdFeatherIcons type="settings" /><span>Settings</span></template>
    <a-menu-item @click="toggleCollapsed" key="starter">
        <router-link to="/users">
          <sdFeatherIcons type="settings" />
          <span>
            Users
          </span>
        </router-link>
    </a-menu-item>

    <a-menu-item @click="toggleCollapsed" key="starter">
        <router-link to="/attributes_maps">
          <sdFeatherIcons type="settings" />
          <span>
            Attribute Maps
          </span>
        </router-link>
    </a-menu-item>
  </a-sub-menu>
  </a-menu>
</template>
<script>
import {
  computed,
  reactive,
  ref,
  toRefs,
  watch,
  watchEffect,
  defineComponent,
} from "vue";
import VueTypes from "vue-types";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import versions from "../demoData/changelog.json";

export default defineComponent({
  name: "AsideItems",
  props: {
    toggleCollapsed: VueTypes.func,
    events: VueTypes.object,
  },
  setup(props) {
    const store = useStore();
    const darkMode = computed(() => store.state.themeLayout.data);
    const mode = ref("inline");
    const { events } = toRefs(props);
    const {
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
    } = events.value;

    const router = computed(() => useRoute());
    const state = reactive({
      selectedKeys: ["home"],
      openKeys: ["dashboard"],
      preOpenKeys: ["dashboard"],
    });

    watchEffect(() => {
      // TODO:: to delete after testing
      // if (router.value.matched.length) {
      //   if (router.value.matched.length > 2) {
      //     state.selectedKeys = [router.value.matched[2].name];
      //     state.openKeys = [router.value.matched[1].name];
      //     state.preOpenKeys = [router.value.matched[1].name];
      //   } else if (router.value.matched.length > 3) {
      //     state.selectedKeys = [router.value.matched[3].name];
      //     state.openKeys = [router.value.matched[1].name];
      //     state.preOpenKeys = [router.value.matched[1].name];
      //   } else {
      //     state.selectedKeys = [router.value.matched[1].name];
      //     state.openKeys = [router.value.matched[1].name];
      //     state.preOpenKeys = [router.value.matched[1].name];
      //   }
      // }
    });

    watch(
      () => state.openKeys,
      (val, oldVal) => {
        state.preOpenKeys = oldVal;
      }
    );

    return {
      mode,
      ...toRefs(state),
      darkMode,
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
      versions,
    };
  },
});
</script>
