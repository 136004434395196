<template>
  <InfoWraper>
    <div class="nav-author">
    </div>

    <LogoutOutlined @click="signOut" style="color: red;"/>
    <div class="nav-author" @click="redirectToUserSettings">
        <a to="#" class="head-example">
          <a-avatar
            src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png"
          />
        </a>
    </div>
  </InfoWraper>
</template>

<script>
import { InfoWraper } from "./auth-info-style";
import { LogoutOutlined } from "@ant-design/icons-vue";

import store from '@/vuex/store';
export default {
  name: "AuthInfo",
  components: {
    InfoWraper,
    LogoutOutlined,
  },
  data() {
    return {
      flah: "english",
      store: store
    }
  },

  methods: {
    signOut() {
      this.$store.dispatch('auth/setUserDetails', null);
      this.$store.dispatch('auth/setUserToken', null);
      this.$store.dispatch('auth/setUserStep', null);
      this.$router.push('/login');
    },

    redirectToUserSettings() {
      this.$router.push({ name: 'users_edit', params: {id: store.state.auth.userDetails.id} });
    }
  }
}
</script>
